export const enum Events {
  // Update events
  UPDATE_SHOW = "update:show",
  UPDATE_ACTION = "update:action",
  UPDATE_SELECTED_OPTIONS = "update:selectedOptions",
  UPDATE_MODEL_VALUE = "update:modelValue",
  UPDATE_CLEAR_USER_DATA = "update:clearUserData",

  // Set events
  SET_DETAILS = "set:details",

  // Select events
  SELECT = "select",

  SCROLL_TO = "scroll:to",
}

// https://www.freecodecamp.org/news/how-event-handling-works-in-vue-3-guide-for-devs/
