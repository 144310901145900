export const user_navigation = [
  {
    name: "Profile",
    icon: "fa-regular fa-user",
  },
  {
    name: "Preferences",
    icon: "fa-solid fa-sliders",
  },
];
