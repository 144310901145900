<template>
  <BaseButton @click="handleLogin" text="Log In" />
</template>

<script setup lang="ts">
import { kebabCase } from "lodash";
import { useAuth0 } from "@auth0/auth0-vue";
import { primary_navigation } from "@/helpers";
import BaseButton from "@/components/base/BaseButton.vue";

const { loginWithRedirect } = useAuth0();
const home_page = primary_navigation.find((n) => n.is_home === true);

const handleLogin = () => {
  loginWithRedirect({
    appState: {
      target: `/${kebabCase(home_page.name)}`,
    },
    authorizationParams: {
      prompt: "login",
    },
  });
};
</script>
