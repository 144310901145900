import { Item, AllRoles, NavType } from "@/helpers";

export const primary_navigation: Item[] = [
  {
    is_home: true,
    name: "User Management",
    description: "Manage users, check their roles and connections",
    icon: "fa-solid fa-user-group",
    current: false,
    roles: [AllRoles.SUPER_ADMIN],
    items: [
      {
        tab: "Tab1",
        name: "Overview",
        current: true,
        icon: "",
        items: [],
      },
      {
        tab: "Tab2",
        name: "Removed Users",
        current: false,
        icon: "",
        items: [],
      },
      {
        tab: "Tab3",
        name: "Archived Users",
        current: false,
        icon: "",
        items: [],
      },
      {
        tab: "Tab4",
        name: "Add New",
        current: false,
        icon: "",
        items: [],
      },
    ],
  },
  {
    name: "Vendor Management",
    description:
      "Manage vendors, check their expertise and product/service list",
    icon: "fa-solid fa-user-shield", // user-gear, user-secret
    current: false,
    roles: [AllRoles.SUPER_ADMIN, AllRoles.VENDOR],
    items: [
      {
        tab: "Tab1",
        name: "Overview",
        current: true,
        icon: "",
        items: [],
      },
      {
        tab: "Tab2",
        name: "Roles",
        current: false,
        icon: "",
        items: [],
      },
      {
        tab: "Tab3",
        name: "Groups",
        current: false,
        icon: "",
        items: [],
      },
    ],
  },
  {
    name: "Community Management",
    description: "Manage your community, find people to chat with or mentor",
    icon: "fa-solid fa-users", // users-rectangle
    current: false,
    roles: [AllRoles.SUPER_ADMIN, AllRoles.CONSUMER],
    items: [
      {
        tab: "Tab1",
        name: "Overview",
        current: true,
        icon: "",
        items: [],
      },
      {
        tab: "Tab2",
        name: "Roles",
        current: false,
        icon: "",
        items: [],
      },
    ],
  },
  {
    name: "Events Management",
    description: "Manage events, schedule and invite people",
    icon: "fa-solid fa-calendar-plus",
    current: false,
    roles: [AllRoles.SUPER_ADMIN, AllRoles.VENDOR],
    items: [
      {
        tab: "Tab1",
        name: "Overview",
        current: true,
        icon: "",
        items: [],
      },
      {
        tab: "Tab2",
        name: "Roles",
        current: false,
        icon: "",
        items: [],
      },
      {
        tab: "Tab3",
        name: "Groups",
        current: false,
        icon: "",
        items: [],
      },
      {
        tab: "Tab4",
        name: "Api Keys",
        current: false,
        icon: "",
        items: [],
      },
    ],
  },
  {
    name: "Category Management",
    description: "Manage users, check their roles and connections",
    icon: "fa-solid fa-server",
    current: false,
    roles: [AllRoles.SUPER_ADMIN, AllRoles.ADMIN, AllRoles.VENDOR],
    items: [
      {
        tab: "Tab1",
        name: "Overview",
        current: true,
        icon: "",
        items: [],
      },
      {
        tab: "Tab2",
        name: "Roles",
        current: false,
        icon: "",
        items: [],
      },
      {
        tab: "Tab3",
        name: "Groups",
        current: false,
        icon: "",
        items: [],
      },
    ],
  },
  {
    name: "Booking & Scheduling",
    description: "Manage users, check their roles and connections",
    icon: "fa-solid fa-calendar-check",
    current: false,
    roles: [...Object.values(AllRoles)],
    items: [
      {
        tab: "Tab1",
        name: "Overview",
        current: true,
        icon: "",
        items: [],
      },
      {
        tab: "Tab2",
        name: "Model",
        current: false,
        icon: "",
        items: [],
      },
    ],
  },
  {
    name: "Billing & Payments",
    description: "Manage users, check their roles and connections",
    icon: "fa-solid fa-circle-dollar-to-slot", // regular fa-money-bill-1, solid: money-check-dollar, dollar-sign, hand-holdinig-dollar
    current: false,
    roles: [...Object.values(AllRoles)],
    items: [
      {
        tab: "Tab1",
        name: "Overview",
        current: true,
        icon: "",
        items: [],
      },
    ],
  },
];
