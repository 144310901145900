import { ref } from "vue";

export const landing_page_navigation = [
  { name: "Home", action: "home", ref: ref(null) },
  { name: "About Us", action: "about", ref: ref(null) },
  // { name: "Projects", action: "projects", ref: ref(null) },
  // { name: "Expertise", action: "expertise", ref: ref(null) },
  // { name: "Skills", action: "skills", ref: ref(null) },
  { name: "Contact Us", action: "contact", ref: ref(null) },
];
